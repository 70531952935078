import NavBar from '../components/navigation/NavBar';
import React, { useState, useEffect } from 'react';
import Particles from 'react-tsparticles';
const tsParticlesOptions = require('../components/presets/tsparticles.json');

function NFTText(props) {
  return <div className='m-3 p-2 sm:m-10'>{props.children}</div>;
}

const OpenSeaNFTImages = (myData) => {
  if (myData.myData && myData.myData.openSeaData ) {
    let myOpenSeaNFTArray = [...myData.myData.openSeaData];

    return (
      <div className='m-3 p-2 sm:m-10'>
        <div className='mx-auto justify-evenly py-2 px-0 sm:py-2 sm:px-0 lg:max-w-full lg:px-0'>
          <div className='grid grid-cols-2 gap-y-6 gap-x-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4'>
            {Object
              .keys(myOpenSeaNFTArray)
              .filter((key) => !myOpenSeaNFTArray[key].NFTCollectionLink.endsWith('/shinatoken') &&
                               !myOpenSeaNFTArray[key].NFTCollectionLink.endsWith('/shinamerch'))
              .map((key) => {
              return (
                // Assign a unique name to each div or React complains
                <div key={myOpenSeaNFTArray[key].NFTCollectionName}>
                  <div className='nft-parent max-w-fit transform overflow-hidden rounded-lg bg-dpink-900 shadow-xl transition duration-500 hover:scale-105'>
                    <a href={myOpenSeaNFTArray[key].NFTCollectionLink}>
                      <img
                        className='object-cover filter duration-700 ease-in-out group-hover:opacity-75'
                        src={myOpenSeaNFTArray[key].NFTCollectionImageLink}
                        alt={myOpenSeaNFTArray[key].NFTCollectionName}
                      />
                    </a>
                    <div className='text-gray-300 nft-title opacity-0 hover:opacity-100 absolute inset-x-0 top-0 flex items-center justify-center pt-2 text-center font-srcpro text-xs'>
                      <a href={myOpenSeaNFTArray[key].NFTCollectionLink}>
                        {myOpenSeaNFTArray[key].NFTCollectionName}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    // if the useState can't find the latest.json file or the openSeaData data within the file
    // Static image paths live in the public directory
    const staticNFTImageArray = [
      './imgs/nft-shina-peach.jpg',
      '/imgs/nft-shina-purple.jpg',
      './imgs/nft-shina-America.png',
      './imgs/nft-shina-8Bit-FullColor.png',
    ];

    return (
      <div className='m-3 p-2 sm:m-10'>
        <div className='mx-auto justify-evenly py-2 px-0 sm:py-2 sm:px-0 lg:max-w-full lg:px-0'>
          <div className='grid grid-cols-2 gap-y-6 gap-x-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4'>
            {staticNFTImageArray.map((myImage) => {
              return (
                // Assign a unique name to each div or React complains
                <div key={myImage}>
                  <div className='max-w-fit transform overflow-hidden rounded-lg bg-dpink-900 shadow-xl transition duration-500 hover:scale-105'>
                    <img
                      className='object-cover filter duration-700 ease-in-out group-hover:opacity-75'
                      src={myImage}
                      alt=''
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export function NFTSection(props) {
  // Retrieves the data inside the ./shinacap/latest.json file and updates the onscreen values
  const [data, setData] = useState();
  // const [isLoaded, setIsLoaded] = useState(false);

  const getData = () => {
    fetch('shinacap/latestv2.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })

      .then(function (myJson) {
        // setIsLoaded(true);
        setData(myJson);
      });
  };

  // Trigger on load/render
  useEffect(() => {
    getData();
  }, []);

  // TSParticles initialization functions
  const particlesInit = async (main) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
  };

  const particlesLoaded = (container) => {};

  return (
    <div className='NFTSection'>
      <div
        className='nextscreen flex flex-grow 
        flex-col overflow-hidden bg-gradient-to-t from-dpink-900 to-dpink-800'
      >
        {/* TSParticles */}
        <Particles
          id='tsparticles-nftsection'
          init={particlesInit}
          loaded={particlesLoaded}
          options={tsParticlesOptions}
        />
        <NavBar name='NFTs' hide='true'/>
        <div className='relative flex flex-grow flex-row'>
          <div className='flex lg:w-3/5'>
            <div className='lg:mr-30 lg:mr-10'>
              <div className='m-4 mt-10 text-center font-srcpro text-dpink-200 md:ml-8'>
                <h1 className='px-4 text-center text-lg text-dpink-50 sm:text-xl md:text-left'>
                  Get a beautiful and rare Shina NFT digital collectible living
                  on the Ethereum blockchain
                </h1>
              </div>

              <div className='text-center font-srcpro text-dpink-200 md:text-left'>
                <NFTText>
                  Each beautiful Shina is uniquely hand drawn. All of our Shina
                  Token NFTs are incredible, but some are rarer than others.
                </NFTText>

                <OpenSeaNFTImages myData={data} />
                <div className='flex flex-row mb-30'>

                  <div className='flex flex-grow flex-col sm:mr-16 sm:ml-10'>
                    <div className='mt-12 text-xl'>
                      <div className='btn-container mb-24 whitespace-nowrap'>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='http://opensea.io/ShinaToken'
                          className='colorful w-40'
                        >
                          BUY ON OPENSEA
                        </a>
                      </div>
                      <div className=''>
                        <span> </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='lg:col lg:-p-10 -mr-0f lex-grow relative hidden lg:visible lg:flex'>
            <img
              className='mt-auto ml-auto'
              style={{ maxWidth: '100%', objectFit: 'scale-down' }}
              src={'./imgs/nft-shina-comet.png'}
              alt='comet shina'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NFTSection;
