import NavBar from '../components/navigation/NavBar';
import DonationStats from '../components/DonationStats';
import React from 'react';

function CharityText(props) {
  return (
    <p className='m-3 border-r-2 border-l-2 border-dpink-800 p-2'>
      {props.children}
    </p>
  );
}

function ShibbButton(props) {

  const [ isTokenVisible, setIsTokenVisible ] = React.useState(false);

  return (
      <button onMouseEnter={() => setIsTokenVisible(true)}
              onMouseLeave={() => setIsTokenVisible(false)}
        type="button" onClick={props.onClickFnc} 
        className={`cursor-not-allowed uppercase flex-grow ${props.extraCss}
                    rounded-lg p-2 mt-2 mb-2
                  bg-gray-700 transition-all shadow-lg hover:shadow-lg 
                  hover:bg-gray-600 text-dpink-300
                  focus:ring-dpink-600 focus:ring-2 focus:outline-none`}>
        
        <div className="flex flex-row flex-grow align-baseline">
          {props.img && 
           <img alt={props.children} src={props.img} 
                className={"h-6 w-6  " + (isTokenVisible? "opacity-100" : "opacity-80")}
                /> }
          <div className="text-center flex-grow">
            {props.children}
          </div>
        </div>
      </button>
  )
}

function ShibbButtonWithInput(props) {
  return (
    <div className={`flex flex-col flex-grow ${props.leftPad? 'xs:ml-2' : ''}`}>
      
      { !props.inputOff && 
        <input className=" bg-dpink-800 rounded-lg p-2 mt-2 mb-2
                        focus:ring-dpink-600 focus:ring-2 focus:outline-none 
                        sm:min-w-[400px]" 
              inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" 
              pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" 
              maxLength="79" spellCheck="false">
        </input>
      }

      <div className="flex flex-col xs:flex-col text-center">

        <div className={`${props.secondBtn? 'w-full flex' : ''} flex flex-grow`}>
          <ShibbButton onClickFnc={props.onClickFnc} 
                       img={props.img}>
            {props.children}
          </ShibbButton>
        </div>

        <div className={`w-full flex ${props.secondBtn? ' ' : ' hidden'}`}>
          
          <ShibbButton onClickFnc={props.onClickFnc} 
                       img={props.img} extraCss="">
            {props.secondBtnTxt}
          </ShibbButton>
        </div>
        
      </div>
    </div>
  )
}

function DonationPanels(props) {

  const [ isAlertVisible, setIsAlertVisible ] = React.useState(false);

  const handleButtonClick = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
        setIsAlertVisible(false);
    }, 1000);
  }

  return (
    <div className="w-full text-dpink-100 sm:w-max ml-4 mr-4 mt-10 mb-20 flex flex-col justify-around items-center font-srcpro">

      <div className="w-full p-4 flex flex-grow flex-col rounded-lg text-center">
        
        <button type="button" onClick={handleButtonClick}
                className="cursor-not-allowed uppercase
                           rounded-lg p-2 mt-2
                           wallet-connect-btn transition-all shadow-lg hover:shadow-lg 
                         hover:bg-blue-600 text-dpink-200
                         focus:ring-dpink-600 focus:ring-2 focus:outline-none">
          Connect Wallet
        </button>

        <div className="text-dpink-400 mt-4">
          <div className={"text-dpink-300 transition-all" + (isAlertVisible? "" : " [color:rgba(0,0,0,0)]")}>
            Coming Soon!
          </div>
        </div>
      </div>

      <div className="p-3 w-full flex flex-col shadow-xl rounded-lg  bg-dgray-100">

        <div className="mb-2 flex flex-row justify-between text-sm text-dpink-200 bg-dpink-800 p-3 rounded-lg">
          <div className="flex flex-col text-dpink-200">
            <div>Available SHI</div>
            <div>Staked SHI</div>
          </div>
          <div className="flex flex-col">
            <div>0</div>
            <div>0</div>
          </div>
        </div>

        <div className="mb-2 flex flex-row justify-between text-sm text-dpink-200 bg-dpink-800 p-3 rounded-lg">
          <div className="flex flex-col text-dpink-200">
            <div>Available SHIB</div>
            <div>Staked SHIB</div>
          </div>
          <div className="flex flex-col">
            <div>0</div>
            <div>0</div>
          </div>
        </div>

        <input className=" bg-dpink-800 rounded-lg p-2 mt-2 mb-2
                      focus:ring-dpink-600 focus:ring-2 focus:outline-none 
                      sm:min-w-[400px]" 
              inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" 
              pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" 
              maxLength="79" spellCheck="false">
        </input>

        <div className="flex flex-col xs:flex-row ">
          <ShibbButtonWithInput img="/favicon.svg" inputOff={true} secondBtn={true} 
                                secondBtnTxt="Withdraw SHI" 
                                
                                onClickFnc={handleButtonClick}>
            Stake SHI
          </ShibbButtonWithInput>

          <ShibbButtonWithInput img="/imgs/shiba.svg" inputOff={true} 
                                secondBtn={true} secondBtnTxt="Withdraw SHIB" 
                                leftPad={true}
                                onClickFnc={handleButtonClick}>
            Stake SHIB
          </ShibbButtonWithInput>
        </div>
      </div>

      <div className="p-3 peer-visited:-4 w-full  flex flex-col mt-10 bg-dgray-100 shadow-xl rounded-lg 
                    text-dpink-100">
        <ShibbButtonWithInput onClickFnc={handleButtonClick}>
          Donate ETH
        </ShibbButtonWithInput>
      </div>
      
    </div>
  )
}

export default function CharitySection(props) {
  return (
    <div
      className='nextscreen flex flex-grow 
                 flex-col overflow-hidden bg-gradient-to-t from-dpink-900 to-dpink-800'
    >
      <NavBar name='Charity' hide='true'/>
      <div className='z-20 flex flex-row'>
        <div className='flex-grow hidden lg:visible lg:flex justify-center'>
          <DonationPanels/>
        </div>
        <div className='flex flex-col lg:w-3/5'>
          <div className='lg:mr-30 xl:mr-10'>
            <DonationStats />

            <div className='mt-6 text-left font-srcpro text-dpink-200 sm:mt-0'>

            <CharityText>
                The Shina Burn Bank (SHIBB) provides an incentive mechanism
                that we hope encourages millions of dollars to be donated to Shared Hope 
                International so they can grow and continue their incredible work
                of rescuing children and providing for those who are less fortunate.{' '}
            </CharityText>

            <CharityText>  
                As donations occur and SHI or SHIB are staked, they will be burned and staking rewards earned.
                See the {' '}
                <a className='text-red-400 hover:underline' href='/whitepaper/ShinaTokenWhitepaper-latest.pdf'>whitepaper</a>
                {' '}for details.
            </CharityText>

            <CharityText>
                For more than 2 decades Shared Hope International has worked tirelessly
                to free enslaved women and children around the world from the sex 
                trafficking industry. They are a highly esteemed organization and have 
                received Charity Navigator's highest rating of{' '}
                <a
                  className='text-red-400 hover:underline'
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.charitynavigator.org/ein/911938635'
                >
                  Exceptional
                </a> 
                {' '}for many years. 
                Some members of the Shina Token core team have been helping Shared Hope 
                International financially for more than a decade!
              </CharityText>  

              <CharityText>
                Let's work together to make the world a better place.
              </CharityText>

              <div className='flex flex-row'>
                <div className='visible flex flex-grow justify-center lg:hidden lg:w-1/2'>
                  <DonationPanels/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
