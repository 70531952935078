import Footer from '../components/navigation/Footer';
import NFTSection from '../sections/NFTSection';
import StoreSection from '../sections/StoreSection';
import HowToBuySection from '../sections/HowToBuySection';
import ShinaTokenSection from '../sections/ShinaTokenSection';
import DonationSection from '../sections/DonationSection';
import CharitySection from '../sections/CharitySection';
import ContractSection from '../sections/ContractSection';

function HomePage() {
  return (
    <div className='HomePage'>
      <ShinaTokenSection />
      <ContractSection />
      <HowToBuySection />
      <CharitySection />
      <StoreSection />
      <NFTSection />
      <DonationSection />
      <Footer />
    </div>
  );
}

export default HomePage;
