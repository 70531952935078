import NavBar from '../components/navigation/NavBar';
import Particles from 'react-tsparticles';

const tsParticlesOptions = require('../components/presets/tsparticles.json');

export default function ShinaTokenSection(props) {
  const particlesInit = async (main) => {
  };

  const particlesLoaded = (container) => {};

  return (
    <div className='ShinaTokenSection'>
      <div
        className='flex flex-grow 
                      flex-col overflow-hidden bg-gradient-to-t from-dpink-900 to-dpink-800'
      >
        <NavBar name='Shina Token' />
        <Particles
          id='ts-particles'
          init={particlesInit}
          loaded={particlesLoaded}
          options={tsParticlesOptions}
        />
        <div className="w-full">
          <picture>
            <source srcSet="/imgs/golfing-cropped.png" media="(min-width: 768px)" />
            <img src="/imgs/golfing.png" className="w-full h-auto" alt="Description" />
          </picture>
        </div>
      </div>
    </div>
  );
}
