import NavBar from '../components/navigation/NavBar';
import MCap from '../components/MCap';
import { useState } from 'react';

function HowToBuyText(props) {
  return (
    <p className='m-3 border-r-2 border-l-2 border-dpink-800 p-2 sm:m-10'>
      {props.children}
    </p>
  );
}

export default function HowToBuySection(props) {
  let [showFlooz, setShowFlooz] = useState(false);

  return (
    <div
      className='nextscreen flex flex-grow 
                 flex-col overflow-hidden bg-gradient-to-t from-dpink-900 to-dpink-800'
    >

      <NavBar name='How to Buy' hide='true' />
      <div className='z-20 flex flex-row'>
        <div className='lg:-p-10 relative -mr-0 hidden flex-grow lg:visible lg:flex inline-flex items-center justify-center'>
          {showFlooz ?
            floozIFrame()
            : <img
              className='mt-auto ml-auto'
              style={{ maxWidth: '100%', objectFit: 'scale-down' }}
              src={'./imgs/lg2-shina-shy.png'}
              alt='pretty shina 2'
            />}
        </div>
        <div className='flex flex-col lg:w-3/5'>
          <div className='lg:mr-30 lg:mr-10'>
            <MCap />

            <div className='mt-6 text-left font-srcpro text-dpink-200 sm:mt-0'>
              <HowToBuyText>
                Shina Token is available for sale on Uniswap and other
                exchanges. Please do not invest more than you are willing to
                lose on new and volatile tokens. Be careful of scammers! Anyone
                can list their coins and use any token symbol. There are no
                token symbol police in Ethereum. Verify tokens you are
                purchasing match the contract address found{' '}
                <a
                  className='text-red-400 hover:underline'
                  target='_blank'
                  rel='noreferrer'
                  href='https://etherscan.io/token/0x243cacb4d5ff6814ad668c3e225246efa886ad5a'
                >
                  here
                </a>
                . Please also read the whitepaper before purchasing.
              </HowToBuyText>

              <HowToBuyText>
                You have caught Shina Inu at its infancy and as such, this token
                is available on very few exchanges. To get started, install a
                crypto wallet such as{' '}
                <a
                  className='text-red-400 hover:underline'
                  target='_blank'
                  rel='noreferrer'
                  href='https://metamask.io/'
                >
                  Metamask
                </a>{' '}
                on your phone or computer. Remember to keep your crypto wallet
                passphrase safe and secure! We will never ask you for your
                secret key or passphrase. Do not trust anyone with it.
              </HowToBuyText>

              <HowToBuyText>
                Once you have your wallet configured, buy some{' '}
                <a
                  className='text-red-400 hover:underline'
                  target='_blank'
                  rel='noreferrer'
                  href='https://ethereum.org/en/eth/'
                >
                  ETH
                </a>{' '}
                using the wallet app or transfer ETH into your wallet using an
                app such as{' '}
                <a
                  className='text-red-400 hover:underline'
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.coinbase.com/'
                >
                  Coinbase
                </a>
                .
              </HowToBuyText>

              <HowToBuyText>
                The last step is to head over to{' '}
                <a
                  className='text-red-400 hover:underline'
                  target='_blank'
                  rel='noreferrer'
                  href='https://app.uniswap.org/#/swap?theme=dark&chain=mainnet&inputCurrency=eth&outputCurrency=0x243cacb4d5ff6814ad668c3e225246efa886ad5a'
                >
                  Uniswap
                </a>
                , click Connect Wallet and start swapping ETH for Shina Inu
                Token (SHI)! You may also use Flooz to swap, but use at your own risk (dyor).
              </HowToBuyText>

              <div className='flex flex-col'>

                <div className="flex flex-row flex-none">
                  {BuyButton('Uniswap', 'https://app.uniswap.org/#/swap?theme=dark&chain=mainnet&inputCurrency=eth&outputCurrency=0x243cacb4d5ff6814ad668c3e225246efa886ad5a')}
                  {BuyButton(showFlooz ? 'Hide Flooz' : 'Flooz', '#', () => setShowFlooz(!showFlooz))}
                </div>

                <div className='visible flex lg:hidden lg:w-1/2 inline-flex justify-center'>
                  {showFlooz ?
                    floozIFrame() :
                    <img
                      className='mt-auto ml-auto'
                      style={{ maxWidth: '100%', objectFit: 'scale-down' }}
                      src={'./imgs/lg2-shina-shy.png'}
                      alt='pretty shina 2'
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function floozIFrame() {
    return <div className='m-auto mb-12 mt-12'>
      <iframe
        title="FloozIFrame"
        width="350"
        height="720"
        allow="clipboard-read *; clipboard-write *; web-share *; autoplay *; payment *"
        src="https://flooz.xyz/embed/trade?swapDisabled=false&swapToTokenAddress=0x243cACb4D5fF6814AD668C3e225246efA886AD5a&swapLockToToken=true&onRampDisabled=false&onRampAsDefault=false&onRampTokenAddress=0x243cACb4D5fF6814AD668C3e225246efA886AD5a&onRampLockToken=true&stakeDisabled=true&network=eth&lightMode=false&primaryColor=%233d2344&backgroundColor=transparent&roundedCorners=10&padding=20&refId=2dCQd7"
      ></iframe>
    </div>
  }

  function BuyButton(text, link, onClick) {
    return <div className='flex flex-initial flex-col grow'>
      <div className='mt-12 text-xl inline-flex items-center justify-center '>
        <div className='btn-container mb-10 whitespace-nowrap'>
          {link === '#' ?
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              onClick={onClick}
              className='colorful w-40 cursor-pointer'
            >{text}</a> :
            <a
              target='_blank'
              rel='noreferrer'
              href={link}
              onClick={onClick}
              className='colorful w-40 cursor-pointer'>{text}</a>}
        </div>
      </div>
    </div>
  }
}
