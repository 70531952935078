import Social from './Social';

export default function NavBar(props) {
  return (
    <>
      <div
        className='navbar z-20 flex justify-between bg-dpink-900 p-3 pl-5  pr-5
                    text-center  '
      >
        <div className='m-auto flex flex-row items-center sm:m-0'>
          <a href='/'>
            <img src={'./favicon.svg'} alt='Shina Icon' className='w-10 xl:w-12' />
          </a>
          <span className=' ml-4 font-audio text-4xl text-dpurp-100 sm:text-5xl'>
            {props.name}
          </span>
        </div>
        <div className={'hidden ' + (props.hide? '' : 'md:flex')}>
          <Social />
        </div>
      </div>
    </>
  );
}
