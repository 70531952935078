import { DuplicateIcon } from '@heroicons/react/outline';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function DonationSection(props) {
  // Copy Function
  const copy = async () => {
    let copyButton = document.getElementById('copy-button');
    triggerCopyButtonText(copyButton);
  };

  const triggerCopyButtonText = (copyButton) => {
    copyButton.innerText = 'Copied!';
    setInterval(function () {
      copyButton.innerText = 'Copy to Clipboard';
    }, 2000);
  };

  function CommunityText(props) {
    return (
      <div className='m-3 p-2 pb-5 text-center font-srcpro text-dpink-200'>
        <div className='flex'>
          <div className='thank-you-text flex items-center'>
            <p>{props.children}</p>
          </div>
        </div>
      </div>
    );
  }

  function DevWallet(props) {
    return (
      <div className='address-wrap'>
        {/* Flex container */}
        <div
          className='flex min-w-0 max-w-xs flex-wrap items-center justify-center space-x-5 
                        overflow-hidden rounded-3xl bg-dgray-200 py-2 px-5 sm:max-w-none'
        >
          {/* Wallet ID */}
          <div className='text-md relative overflow-hidden text-ellipsis px-2 py-1 pl-5 font-audio text-dpurp-100 md:max-w-none'>
            <p id='wallet-address' className='overflow-hidden text-ellipsis'>
              <CopyToClipboard text={props.children}>
                <span>{props.children}</span>
              </CopyToClipboard>
            </p>
            <div className='address-hider'></div>
          </div>

          {/* Copy Button */}
          <div className='flex-grow py-1 pr-5 '>
            <CopyToClipboard text={props.children}>
              <div className='btn-copy text-xs' onClick={copy}>
                <DuplicateIcon className=' mr-1 h-5 w-5' />{' '}
                <div id='copy-button'>Copy to Clipboard</div>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='DonationSection'>
      <div className='nextscreen'>
        <div className='flex min-w-0 flex-col items-center bg-dgray-100 pt-10 pb-10'>
          <span className='pb-2 font-audio text-4xl text-dpurp-200 sm:text-5xl'>
            Donate
          </span>
          <CommunityText>
            If you would like to contribute to the Dev Team, please send ETH or
            SHI here. Thank you for your support!
          </CommunityText>
          <DevWallet>0x2Aa50a8AD20d577D40Ee2D26006284BB1406A458</DevWallet>
        </div>
      </div>
    </div>
  );
}
