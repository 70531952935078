import React from 'react';

export function DonationStats() {

  function Stat(num, title) {
    return (
      <div className='cap mt-6 mr-10 md:pt-0'>
        <div className='font-audio text-3xl text-dpink-200'>{num}</div>
        <div className='text-xs text-dpink-200'>{title}</div>
      </div>
    );
  }

  return (
    <div className='text-left font-srcpro text-dpink-200'>
      <div
        className='m-3 grid grid-flow-row grid-cols-2 lg:grid-cols-4 flex-wrap xl:justify-between
                   border-r-2 border-l-2 border-dpink-800 p-2 justify-items-stretch sm:justify-start'
      >
        {Stat(
          '$50,000',
          <div>Donated </div>
        )}
        {Stat('$' + 0, 'Jackpot')}
        {Stat('' + 0, 'SHI Burned')}
        {Stat('' + 0, 'SHI Staked')}

        {Stat('' + 0, 'SHIB Burned')}
        {Stat('' + 0, 'SHIB Staked')}

        {Stat('' + 0, 'Epoch')}
        {Stat('TBD', 'Next Epoch')}
      </div>
    </div>
  );
}

export default DonationStats;
