import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// Represents current pool pair balances
// For reference only. Convert to ts some day to utilize
export class MarketData {
  mCapEth;
  burntShiAmt;
  shina;
  weth;
  ethUsd;
  totalSupply;
  timestamp;
  openSeaData;
}

function convToLcl(num) {
  return parseInt(num.toFixed(2)).toLocaleString('en-US');
}

function getTril(data) {
  return Math.round((data.totalSupply - data.burntShiAmt) / 100000000000) / 10;
}

function getHolderCount(data) {

  if ('info' in data && 'holdersCount' in data.info) {
    return data.info.holdersCount
  }
  else {
    return 0
  }  
}

export function MCap() {
  const jsonDataPath = [useLocation().pathname, 'shinacap/latestv2.json'].join(
    ''
  );

  // Retrieves the data inside the ./shinacap/latest.json file and updates the onscreen values
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    fetch(jsonDataPath, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })

      .then(function (myJson) {
        setData(myJson);
      });
  }, [jsonDataPath]);

  // Trigger on load/render
  useEffect(() => {
    getData();
  }, [getData]);

  // Triggers every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 300000);
    return () => clearInterval(interval);
  }, [getData]);

  function Stat(num, title) {
    return (
      <div className='cap mt-6 mr-10 md:pt-0'>
        <div className='font-audio text-3xl text-dpink-200'>{num}</div>
        <div className='text-xs text-dpink-200'>{title}</div>
      </div>
    );
  }

  return (
    <div className='text-left font-srcpro text-dpink-200'>
      <div
        className='m-3 flex flex-wrap flex-col sm:flex-row
                   border-r-2 border-l-2 border-dpink-800 p-2 sm:m-10 sm:justify-between'
      >
        {Stat(
          '$' + convToLcl(data.mCapEth * data.ethUsd),
          <div>Market CAP </div>
        )}
        {Stat(getTril(data) + ' Trillion', 'Total Supply')}
        {Stat(convToLcl(getHolderCount(data)), 'Hodlers')}
        {Stat('$' + convToLcl(+data.ethUsd), 'Eth Price (USD)')}
      </div>
    </div>
  );
}

export default MCap;
