import NavBar from '../components/navigation/NavBar';

function StoreText(props) {
  return <div className='m-3 p-2 sm:m-10'>{props.children}</div>;
}

const Images = () => {
  
  const storePics = [
    {
      path: 'imgs/store/mug2.jpg',
      desc: 'Shina Mug',
    },
    {
      path: 'imgs/store/tshirt.jpg',
      desc: 'Shina T-Shirt'
    },
    {
      path: 'imgs/store/phone-case.jpg',
      desc: 'Shina Phone Case'
    },
    {
      path: 'imgs/store/mug.jpg',
      desc: 'Shina Mug'
    },
  ]

  return (
    <div className='m-3 p-2 sm:m-10'>
      <div className='mx-auto justify-evenly py-2 px-0 sm:py-2 sm:px-0 lg:max-w-full lg:px-0'>
        <div className='grid grid-cols-2 gap-y-6 gap-x-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4'>
          {storePics.map((item) => {
            return (
              // Assign a unique name to each div or React complains
              <div key={item.path}>
                <div className='max-w-fit transform overflow-hidden rounded-lg bg-dpink-900 shadow-xl transition duration-500'>
                  <img
                    className='object-cover filter duration-700 ease-in-out group-hover:opacity-75'
                    src={item.path}
                    alt='item.desc'
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export function StoreSection(props) {

  return (
    <div className='StoreSection'>
      <div
        className='nextscreen flex flex-grow 
        flex-col overflow-hidden bg-gradient-to-t from-dpink-900 to-dpink-800'
      >
        
        <NavBar name='Store' hide='true'/>
        <div className='relative flex flex-grow flex-row'>
          <div className='flex lg:w-3/5 mb-20 lg:mb-32'>
            <div className='lg:mr-30 lg:mr-10'>
              <div className='m-4 mt-10 text-center font-srcpro text-dpink-200 md:ml-8'>
                <h1 className='px-4 text-center text-lg text-dpink-50 sm:text-xl md:text-left'>
                  Help spread the word about Shina and look great doing it!
                </h1>
              </div>

              <div className='text-center font-srcpro text-dpink-200 md:text-left'>
                <StoreText>
                  Visit our community driven store and grab some high quality items for your friends and family.
                </StoreText>

                <Images />

                <div className='flex flex-row'>
                  
                  <div className='flex flex-grow flex-col sm:mr-16 sm:ml-10'>
                    <div className='mt-12 text-xl'>
                      <div className='btn-container whitespace-nowrap'>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://shinamerch.com'
                          className='colorful w-40'
                        >
                          BUY Shina Merch
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreSection;
