import { DuplicateIcon } from '@heroicons/react/outline';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ContractSection(props) {
  // Copy Function
  const copy = async () => {
    let copyButton = document.getElementById('copy-button');
    triggerCopyButtonText(copyButton);
  };

  const triggerCopyButtonText = (copyButton) => {
    copyButton.innerText = 'Copied!';
    setInterval(function () {
      copyButton.innerText = 'Copy to Clipboard';
    }, 2000);
  };

  function CommunityText(props) {
    return (
      <div className='m-3 p-2 pb-5 text-center font-srcpro xl:text-lg text-dpink-200'>
        <div className='flex'>
          <div className='thank-you-text flex items-center'>
            <p>{props.children}</p>
          </div>
        </div>
      </div>
    );
  }

  function DevWallet(props) {
    return (
      <div className=''>
        <div
          className='flex min-w-0 max-w-xs flex-wrap items-center justify-center
                        overflow-hidden rounded-3xl bg-dgray-200 p-2 px-4 sm:m-2 sm:max-w-none'
        >
          {/* Wallet ID */}
          <div className='text-md relative overflow-hidden text-ellipsis font-audio
             text-dpurp-100'>
            <p id='wallet-address' className='overflow-hidden text-ellipsis'>
              <CopyToClipboard text={props.children}>
                <span>{props.children}</span>
              </CopyToClipboard>
            </p>
            <div className='address-hider'></div>
          </div>

          {/* Copy Button */}
          <div className='py-2'>
            <CopyToClipboard text={props.children}>
              <div className='btn-copy text-xs' onClick={copy}>
                <DuplicateIcon className=' mr-1 h-5 w-5' />{' '}
                <div id='copy-button'>Copy to Clipboard</div>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='ContractSection'>
      <div className='nextscreen'>
        <div className='flex z-30 min-w-0 flex-col items-center bg-dgray-100 pt-10 pb-10'>
          <span className='text-center p-2 font-audio text-2xl xl:text-3xl text-dpurp-200'>
            A Decentralized Meme Token on the Ethereum Blockchain
          </span>
          <CommunityText>
            Our mission is to give back to the crypto community, give to charity, and win the heart 
            of the greatest token of all time, Shiba Inu! 
          </CommunityText>
          <DevWallet>0x243cACb4D5fF6814AD668C3e225246efA886AD5a</DevWallet>
          <div className='text-neutral-700 font-audio font-extralight text-sm'>
            ERC20 Contract Address
          </div>
        </div>
      </div>
    </div>
  );
}
